<template>
  <v-container>
    <v-row>
      <v-col>1</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "VedsOpen"
}
</script>

<style scoped>

</style>